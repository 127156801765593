import React from "react";
import styled from "styled-components";
// Components
// import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// Assets
import collab from "../../assets/img/illustrations/collab.png";
import services  from "../../assets/services-data";

export default function Services() {
  return (
    <Wrapper id="services">
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font40 " style={{ padding: "30px s0" }}>
              What We Provide
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            {services.map((services, index) => {
              return (
                <ServiceBoxWrapper>
                  <ServiceBox
                    icon={services?.img}
                    title={services.title}
                    subtitle={services.text}
                  />
                </ServiceBoxWrapper>
              );
            })}
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">ABOUT US</h4>
                <h2 className="font40 extraBold">Why Heofon</h2>
                <p className="font12">
                Heofon is a professional, technology-driven platform comprising multidisciplinary experts including Chartered Accountants, 
                Company Secretaries, and Lawyers. Our mission is to deliver practical solutions to individuals, startups, and other business organizations, 
                facilitating their growth potential at an accessible price point.
                </p>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                    <AddImgWrapp1 className="radius8">
                      <img
                        src={collab}
                        alt="collab"
                      />
                    </AddImgWrapp1>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const ServiceBoxWrapper = styled.div`
  width: calc(33.33% - 2.5%); /* 3 items per row with a bit of margin between */
  margin-bottom: 5%;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
width: 100%;
margin-top: 20px;
  img {
    width: 100%;
    height: auto;
    margin: 100px 0px 0px 0px;
  }
`;
