import confidential from '../assets/img/illustrations/confidential.png'
import Affordable from '../assets/img/illustrations/affordable.png'
import satisfaction from '../assets/img/illustrations/satisfaction.png'
import experts from '../assets/img/illustrations/experts.png'
import services from '../assets/img/illustrations/services.png'
import fee from '../assets/img/illustrations/fee.png'
const projects = [
  {
    title: "Confidential and Safe",
    text: "Heofon provides all services with confidential & safe. We are not share any type of information with other.",
    img:confidential
  },
  {
    title: "Affordable",
    text: "Heofon is a professional technology driven platform to provide concrete solution to individuals, start-ups.",
    img: Affordable
  },
  {
    title: "Guaranteed Satisfaction",
    text: "For any success company, customer satisfication is main part. Therefore we continue work.",
    img: satisfaction
  },
  {
    title: "Expert CA/CS/Lawyers",
    text: "Heofon is a professional technology driven platform of multidisciplined experts like CA/CS/Lawyers spanning",
    img: experts
  },
  {
    title: "Excellent Advisory Services",
    text: "Heofon is a bunch of excecent & highly experience of legal advisor expert which always ready to resolve any type of legal.",
    img: services
  },
  {
    title: "No Hidden Fee",
    text: "Sprinthub ensures that all the services are delivered with full transparency with no hidden charge in cost.",
    img: fee
  },
];
export default projects;