import React, { useEffect } from 'react';
import styled from 'styled-components';
import illustration1 from '../../assets/img/banner/19.png';
import illustration2 from '../../assets/img/banner/36.png';
import illustration3 from '../../assets/img/banner/45.png';
import illustration4 from '../../assets/img/banner/8.png';
import illustration5 from '../../assets/img/banner/9.png';
import illustration6 from '../../assets/img/banner/2.png';
import illustration7 from '../../assets/img/banner/28.png';
import illustration8 from '../../assets/img/banner/37.png';
import illustration9 from '../../assets/img/banner/31.png';
import illustration10 from '../../assets/img/banner/6.png';

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw; /* Set the maximum width to the width of the viewport */
  z-index: -1;

  @media (min-width: 768px) {
    max-width: 768px; /* Adjust for laptop screens */
  }

  @media (min-width: 992px) {
    max-width: 992px; /* Adjust for larger laptop/desktop screens */
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 50px; /* Adjust illustration size */
  height: 50px; /* Adjust illustration size */
  background-size: contain;
  background-repeat: no-repeat;
  will-change: transform; /* Improve animation performance */
  transition: transform 9.8s linear; /* Adjust animation duration */
`;

const getRandomPosition = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomRotation = () => {
  return Math.random() * 360;
};

function randomize()  {
    const illustrations = document.querySelectorAll('.background-illustration');
    illustrations.forEach((illustration) => {
      const x = getRandomPosition(0, window.innerWidth);
      const y = getRandomPosition(0, window.innerHeight);
      const rotation = getRandomRotation();
      illustration.style.transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`;
    });
};

const Background = () => {
  useEffect(() => {
    randomize();
  }, []);

  setInterval(() => {
    randomize();
  }, 10000);

  return (
    <BackgroundContainer>
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration1})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration2})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration3})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration4})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration5})` }} />
      {/* <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration6})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration7})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration8})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration9})` }} />
      <BackgroundImage className="background-illustration" style={{ backgroundImage: `url(${illustration10})` }} /> */}
    </BackgroundContainer>
  );
};

export default Background;
