import finance from '../assets/img/icons/finance.png'
import registration from '../assets/img/icons/registration.png'
import Trademark from '../assets/img/icons/trademark.png'
import compliance from '../assets/img/icons/compliances.png'
import tax from '../assets/img/icons/tax.png'
import legal from '../assets/img/icons/legal.png'
const services = [
  {
    title: "Start New Business",
    text: "For starting any business in India, we are help to provide suitable popular and an effective environment for higher growth aspirants.",
    tag: "Heofon",
    author: "Heofon",
    img:finance
  },
  {
    title: "Registration",
    text: "Heofon will provide you all the necessary services n one roof and provide all registration you require and also guide for other compliance.",
    tag: "Heofon",
    author: "Heofon",
    img: registration
  },
  {
    title: "Trademark/IP",
    text: "Trademark are help to make business as brand in eye of customers. It's also helps in increasing sales by attracting more and more customers",
    tag: "Heofon",
    author: "Heofon",
    img: Trademark
  },
  {
    title: "Compliances",
    text: "Heofon has a team of experts providing you the best assistance, timely delivery",
    tag: "Heofon",
    author: "Heofon",
    img: compliance
  },
  {
    title: "Tax",
    text: "Heofon can assist with filing tax returns for various types of income and entities, ensuring comprehensive support tailored to your needs.",
    tag: "Heofon",
    author: "Heofon",
    img: tax
  },
  {
    title: "Legal Drafting",
    text: "To prevent any ambiguity concerning the relationship between customers and the company, the agreement encompasses...",
    tag: "Heofon",
    author: "Heofon",
    img: legal
  },
];
export default services;