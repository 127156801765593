import React from 'react';
import styled from "styled-components";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const WhatsWidget = () => {
  return (
    <WhatsWrapper>
      <WhatsAppWidget 
        phoneNumber="+919846651504" // Replace with your WhatsApp Business number
        textReplyTime="Typically replies within a few hours" // Optional
        companyName="Heofon✅"
      />
    </WhatsWrapper>
  );
};

export default WhatsWidget;

const WhatsWrapper = styled.div`
  z-index: 99;
`;