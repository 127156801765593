import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
// import Sidebar from "../Nav/Sidebar";
// import Backdrop from "../Elements/Backdrop";
// Assets
// import LogoIcon from "../../assets/svg/Logo";
// import BurgerIcon from "../../assets/svg/BurgerIcon";
import WhiteLogo from "../../assets/img/Logo/WhiteLogo.png";
import DarkLogo from "../../assets/img/Logo/DarkLogo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  // const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const LinkScrollStyle = { padding: "10px 15px", color: "#ffffff" };
  const LinkStickyStyle = { padding: "10px 15px" };
  return (
    <>
      {/* <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} /> */}
      {/* {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />} */}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={
          y > 100
            ? { height: "60px", backgroundColor: "#1c3053", color: "#ffffff" }
            : { height: "80px" }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            {y > 100 ? (
              <img
                src={WhiteLogo}
                width="230px"
                height="210px"
                alt="WhiteLogo"
              />
            ) : (
              <img src={DarkLogo} width="230px" height="210px" alt="darkLogo" />
            )}
          </Link>
          {/* <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper> */}
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="projects"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Features
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="blog"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Blog
              </Link>
            </li> */}
            {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="pricing"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Pricing
              </Link>
            </li> */}
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={y > 100 ? LinkScrollStyle : LinkStickyStyle}
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <a
                href="/"
                className="radius8 lightBg"
                style={{ padding: "10px 15px" }}
              >
                <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Get Started
                </Link>
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
// const BurderWrapper = styled.button`
//   outline: none;
//   border: 0px;
//   background-color: transparent;
//   height: 100%;
//   padding: 0 15px;
//   display: none;
//   @media (max-width: 760px) {
//     display: block;
//   }
// `;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
